import { useState, useEffect } from "react";
import { motion } from 'framer-motion';

function Game({loadCardImage, setLoadCardImage, shuffledCards, showGameOver, setShowGameOver, setShowGame, showGame, gameLengthSelection, setGameLengthSelection, score, setScore, cardLengths, valueForGameLength, setValueForGameLength, setIsHoveredOrSelected, setShowStartScreen, setCategoriesSelected, setTrainerClass, setMostRecentToggle}) {
  const [answer, setAnswer] = useState('');
  const [guess, setGuess] = useState('');
  const [roundCounter, setRoundCounter] = useState(0);
  const [hasUsedStageHint, setHasUsedStageHint] = useState(false);
  const [hasUsedTypeHint, setHasUsedTypeHint] = useState(false);
  const [hasUsedAttackHint, setHasUsedAttackHint] = useState(false);
  const [showStageHint, setShowStageHint] = useState(false);
  const [showTypeHint, setShowTypeHint] = useState(false);
  const [showAttackHint, setShowAttackHint] = useState(false);
  const [verticalDistance, setVerticalDistance] = useState(26); // Initial value for verticalDistance
  const [horizontalDistance, setHorizontalDistance] = useState(-130); // Initial value for horizontalDistance
  const [cardScale, setCardScale] = useState(2);
  const [showAnswerCard, setShowAnswerCard] = useState(false);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [showIncorrectAnswer, setShowIncorrectAnswer] = useState(false);
  const [showTransitionScreen, setShowTransitionScreen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isEnterDebounced, setIsEnterDebounced] = useState(false);
  const [hasTranslated, setHasTranslated] = useState(false);

  const gameVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 }
  }

  // Levenshtein Distance Function
  function levenshtein(a, b) {
    const matrix = [];
    let i, j;

    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    // Initialize the matrix
    for (i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }
    for (j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    // Fill in the matrix
    for (i = 1; i <= b.length; i++) {
      for (j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) === a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1));
        }
      }
    }

    return matrix[b.length][a.length];
  }

  // updates input value as its typed
  const handleInputChange = (event) => {
    const updatedGuess = event.target.value;
    setGuess(updatedGuess);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !isEnterDebounced) {
      handleEnter();
      setIsEnterDebounced(true); // Set the debounce flag
      // Reset the flag after a delay
      setTimeout(() => {
        setIsEnterDebounced(false);
      }, 1000); // Adjust the delay as needed
    }
  };  

  // greys out stage hint button and shows the stage hint when button is clicked
  const stageHintClicked = () => {
    if (!hasUsedStageHint) {
      setHasUsedStageHint(true);
      setShowStageHint(true);
      setShowTypeHint(false); // Hide type hint
      setShowAttackHint(false); // Hide attack hint
    }
  };

  // greys out type hint button and shows the type hint when button is clicked
  const typeHintClicked = () => {
    if (!hasUsedTypeHint) {
      setHasUsedTypeHint(true);
      setShowTypeHint(true);
      setShowStageHint(false); // Hide stage hint
      setShowAttackHint(false); // Hide attack hint
    }
  };
  
  const attackHintClicked = () => {
    if (!hasUsedAttackHint) {
      setHasUsedAttackHint(true);
      setShowAttackHint(true);
      setShowStageHint(false); // Hide stage hint
      setShowTypeHint(false); // Hide type hint
    }
  };
  
  const handleHintKeyDown = (event, hintType) => {
    if (event.key === 'Enter') {
      if (hintType === 'stage') {
        stageHintClicked();
      } else if (hintType === 'type') {
        typeHintClicked();
      } else if (hintType === 'attack') {
        attackHintClicked();
      }
    }
  }
    
  // calculates card position given scale for round
  const CardPosition = (cardScale) => {
    let verticalTop, verticalBottom, horizontalLeft, horizontalRight;
    // Define ranges based on the scale NEEDS REVISION
    if (cardScale === 2) {
      verticalTop = 150;
      verticalBottom = 70; // bottom + 40
      horizontalLeft = 200;
      horizontalRight = -165;
    } else if (cardScale === 7) {
      verticalTop = 1545; 
      verticalBottom = 280; // bottom + 80
      horizontalLeft = 1450;
      horizontalRight = -1415;
    } else if (cardScale === 8) {
      verticalTop = 1831; // 286
      verticalBottom = 322; // bottom + 120 / 42
      horizontalLeft = 1716; // 266
      horizontalRight = -1665; // -250
    } else if (cardScale === 9) {
      verticalTop = 2108; // 277
      verticalBottom = 362; // bottom + 160 / 40
      horizontalLeft = 1965; // 249
      horizontalRight = -1916; // -251
    } else if (cardScale === 10) {
      verticalTop = 2392; // 284
      verticalBottom = 415; // bottom + 200 // 53
      horizontalLeft = 2218; // 253
      horizontalRight = -2165; // -249
    } else {
      // Linear interpolation formula to calculate values between 2 and 10
      const scaleDifference = cardScale - 7;
      const verticalTopDifference = (2392 - 1545) / 3;
      const verticalBottomDifference = (415 - 280) / 3;
      const horizontalLeftDifference = (2218 - 1450) / 3;
      const horizontalRightDifference = (-2165 + 1415) / 3;

      verticalTop = 1545 + scaleDifference * verticalTopDifference;
      verticalBottom = 280 + scaleDifference * verticalBottomDifference;
      horizontalLeft = 1450 + scaleDifference * horizontalLeftDifference;
      horizontalRight = -1415 + scaleDifference * horizontalRightDifference;
    }

    const verticalDistance = Math.floor(Math.random() * (verticalBottom - verticalTop + 1)) + verticalTop;
    const horizontalDistance = Math.floor(Math.random() * (horizontalRight - horizontalLeft + 1)) + horizontalLeft;

    setVerticalDistance(verticalDistance);
    setHorizontalDistance(horizontalDistance);
  };

  // actions if its the last card in game
  const gameOverActions = () => {
    setTimeout(() => {
      setShowGame(false);
      setShowGameOver(true);
      setGuess('');
      setTimeout(() => {
        setProgress(0);
        setRoundCounter(0);
        setShowAnswerCard(false);
        setShowIncorrectAnswer(false);
        setShowCorrectAnswer(false);
        setShowTransitionScreen(false);
        setHasTranslated(false);
        setShowStageHint(false);
        setShowTypeHint(false);
        setShowAttackHint(false);
      }, 100);
    }, 2000);
  };

  // actions if there are more cards left in game
  const nextCardActions = () => {
    setTimeout(() => {
      setRoundCounter(prev => prev+1);
      setGuess('');
      setLoadCardImage(false);
      setShowStageHint(false);
      setShowTypeHint(false);
      setShowAttackHint(false);
      setShowAnswerCard(false);
      setShowIncorrectAnswer(false);
      setShowCorrectAnswer(false);
      setShowTransitionScreen(false);
      setHasTranslated(false);
    }, 2000);
  };

  // handles different answer types when submitted and modifies respective states   
  const handleEnter = () => {
    const cardsLength = shuffledCards.length;
    const finalGuess = guess.toLowerCase().replace(/\s/g, '');
    const levenshteinDistance = levenshtein(finalGuess, answer.toLowerCase());
    const typoThreshold = 2; // Set your typo allowance threshold
  
    if  (loadCardImage) {
      // do nothing if they enter an empty string
      if (finalGuess === '') {
      } // actions if its close enough to the CORRECT answer for the LAST card
        else if (levenshteinDistance <= typoThreshold && cardsLength-1 === roundCounter) {
        setShowTransitionScreen(true);
        setScore(prev => prev+1);
        setTimeout(() => {
          setShowAnswerCard(true);
          setShowCorrectAnswer(true);
          gameOverActions();
          setHasTranslated(true);
        }, 300);
      } // actions if its NOT close enough and for the LAST card
        else if (levenshteinDistance > typoThreshold && cardsLength-1 === roundCounter) {
        setShowTransitionScreen(true);
        setTimeout(() => {
          setShowAnswerCard(true);
          setShowIncorrectAnswer(true);
          gameOverActions();
          setHasTranslated(true);
        }, 300);
      } // actions if its close enough to the CORRECT answer & there are more cards left
        else if (levenshteinDistance <= typoThreshold) {
        setScore(prev => prev+1);
        setShowTransitionScreen(true);
        setTimeout(() => {
          setShowCorrectAnswer(true);
          setShowAnswerCard(true);
          nextCardActions();
          setHasTranslated(true);
        }, 300); 
      } // actions if its NOT close enough & there are more cards left
        else if (levenshteinDistance > typoThreshold) {
        setShowTransitionScreen(true);
        setTimeout(() => {
          setShowAnswerCard(true);
          setShowIncorrectAnswer(true);
          nextCardActions();
          setHasTranslated(true);
        }, 300);
      } 
    }
  };

  // if user presses return home button, reset all states
  const handleClick = () => {
    setIsHoveredOrSelected(false);
    setLoadCardImage(false);
    setShowGameOver(false);
    setShowStartScreen(true);
    setCategoriesSelected({ wotc: false, ex: false, dpp: false, hgss: false, bw: false, xy: false, sm: false, swsh: false, sv: false });
    setGameLengthSelection({tenCards: false, twentyCards: false, fiftyCards: false, oneHundredCards: false,});
    setScore(0);
    setTrainerClass('');
    setMostRecentToggle([]);
    setShowGame(false);
    setShowStageHint(false);
    setShowTypeHint(false);
    setShowAttackHint(false);
    setRoundCounter(0);
    setGuess('');
    setShowAnswerCard(false);
    setShowIncorrectAnswer(false);
    setShowCorrectAnswer(false);
    setShowTransitionScreen(false);
    setProgress(0);
    setHasTranslated(false);
  };
  
  // sets the answer of the pokemon for every round & calculates the scale value for every round & updates progress bar
  useEffect(() => {

    // calculates scale for each round given first and last scale, game length and round number
    const calculateScaleTwo = (firstScale, lastScale) => {
      if (roundCounter === 0) {
        setCardScale(firstScale);
      } else if (roundCounter === valueForGameLength - 1) {
        setCardScale(lastScale);
      } else {
        const scaleDifference = lastScale - firstScale;
        const scaleIncrement = scaleDifference / (valueForGameLength - 1);
        const currentScale = firstScale + scaleIncrement * roundCounter;
        setCardScale(currentScale);
      } 
    }
    // calls the calculatescale2 function with a different last scale based on game length
    const CalculateScale = (valueForGameLength) => {
      if (valueForGameLength === 10) {
        calculateScaleTwo(2, 7);
      } else if (valueForGameLength === 20) {
        calculateScaleTwo(2, 8);
      } else if (valueForGameLength === 50) {
        calculateScaleTwo(2, 9);
      } else if (valueForGameLength === 100) {
        calculateScaleTwo(2, 10);
      }
    };

    const updateProgress = () => {
      setProgress(roundCounter+1);
    };

    const cardName = shuffledCards[roundCounter].name;
    setAnswer(cardName.toLowerCase().replace(/\s/g, ''));
    updateProgress();
    CalculateScale(valueForGameLength)
  }, [roundCounter, shuffledCards, valueForGameLength])

  // calculates img posititon for every new scale value for every round 
  useEffect(() => {
    CardPosition(cardScale);
  }, [cardScale]);

  // pre loads the image for every round 
  useEffect(() => {
    const imageToLoad = new Image();
    imageToLoad.src = shuffledCards[roundCounter].images.large;
    imageToLoad.onload = () => {
      setLoadCardImage(true);
    };

  }, [roundCounter, setLoadCardImage, shuffledCards]);

  return(
    <section id='game-screen-container'>
    <motion.div id='game-screen' className='relative'
    key='game-screen'
    variants={gameVariants}
    initial='hidden'
    animate='visible'
    exit='exit'
    transition={{ duration: 0.1, ease: [0.42, 0, 0.58, 1] }}>
      <div className={`absolute dim-game left-0 hide-dim ${(showTransitionScreen) && 'show-dim'}`}></div>
      <nav id='navbar' className='flex justify-between items-center w-full fixed z-10'>
        <div className='left'>
          <button className='home-button flex items-center' onClick={handleClick} tabIndex={3} aria-label='Click To Return To The Home Screen.'>
            <img src='/images/home.png' alt=''></img>
            <h4>Home</h4>
          </button>
        </div>
        <div className='middle' tabIndex={4} aria-label={`This Is Round ${progress} Out Of ${valueForGameLength}`}>
          <h4 className="progress-bar-text" aria-hidden='true'>Round {progress}/{valueForGameLength}</h4>
        </div>
        <div className='right' aria-label={`Your Score Is: ${score}`}>
          <h4 aria-hidden='true' tabIndex={5}>Correct: {score}</h4>
        </div>
      </nav>
      <nav id='hint-bar' className='fixed w-full z-10 flex items-center justify-between'>
        <div className='left flex items-center'>
          <h4 className='lifelines-text inline-block mr-2' aria-label='Hints' tabIndex={10}>Hints:</h4>
          <div className="lifelines flex">
            <div onClick={() => stageHintClicked()} onKeyDown={(event) => handleHintKeyDown(event, 'stage')} role='button' aria-label={`Hint Number One. Pokémon Stage. You ${hasUsedStageHint ? 'Have Already Used This Hint And Can No Longer Select It.' : 'Have Not Used This Hint Yet. You Can Only Select It Once. The Hint Will Be Revealed At The End Of The Nav Bar.'}`} tabIndex={11} className={`hint mr-2 dotted flex justify-center items-center ${hasUsedStageHint && 'lifeline-selected'}`}>Stage</div>
            <div onClick={() => typeHintClicked()} onKeyDown={(event) => handleHintKeyDown(event, 'type')} role='button' aria-label={`Hint Number Two. Pokémon Type. You ${hasUsedTypeHint ? 'Have Already Used This Hint And Can No Longer Select It.' : 'Have Not Used This Hint Yet. You Can Only Select It Once. The Hint Will Be Revealed At The End Of The Nav Bar.'}`} tabIndex={12} className={`hint mr-2 dotted flex justify-center items-center ${hasUsedTypeHint && 'lifeline-selected'}`}>Type</div>
            <div onClick={() => attackHintClicked()} onKeyDown={(event) => handleHintKeyDown(event, 'attack')} role='button' aria-label={`Hint Number Three. Pokémon Attack. You ${hasUsedAttackHint ? 'Have Already Used This Hint And Can No Longer Select It.' : 'Have Not Used This Hint Yet. You Can Only Select It Once. The Hint Will Be Revealed At The End Of The Nav Bar.'}`} tabIndex={13} className={`hint mr-2 dotted flex justify-center items-center ${hasUsedAttackHint && 'lifeline-selected'}`}>Attack</div>
          </div>
        </div>
        <div className='right' tabIndex={(showStageHint || showTypeHint || showAttackHint) ? 14 : -1}>
          <div className={`hide-hint ${showStageHint && 'show-hint'}`}>
            <h4 aria-label={`You have selected the Stage Hint. This Pokémon Stage is ${shuffledCards[roundCounter].subtypes.slice(0, 8)}`} className='normal-case'>{shuffledCards[roundCounter].subtypes.slice(0, 8)}</h4>
          </div>
          <div className={`hide-hint ${showTypeHint && 'show-hint'}`}>
            <h4 aria-label={`You have selected the Type Hint. This Pokémon Type is ${shuffledCards[roundCounter].types}`} className='normal-case'>{shuffledCards[roundCounter].types}</h4>
          </div>
          <div className={`hide-hint ${showAttackHint && 'show-hint'}`}>
            <h4 aria-label={`You have selected the Attack Hint. This Pokémon Attack is ${shuffledCards[roundCounter].attacks[0].name}`} className='normal-case'>{shuffledCards[roundCounter].attacks[0].name}</h4>
          </div>
        </div>
      </nav>

      <div id='content' className='container relative flex px-20 h-full items-center justify-center'>
        <div className={`answer-reveal hide-answer absolute top-0 ${(showCorrectAnswer || showIncorrectAnswer) && 'show-answer'}`} >
          {showCorrectAnswer && (
            <div tabIndex={showCorrectAnswer ? 1 : -1} aria-label='Correct' className='correct-text-reveal relative'>
              <img src='/images/correct.svg' className='mx-auto h-full' alt=''></img>
            </div>
          )}
          {showIncorrectAnswer && (
            <div tabIndex={showIncorrectAnswer ? 1 : -1} aria-label='Wrong' className='incorrect-text-reveal incorrect'>
              <img src='/images/wrong.svg' className='mx-auto h-full' alt=''></img>
            </div>
          )}
          <div className="correct-img-reveal mx-auto flex flex-col items-start" tabIndex={(showAnswerCard && loadCardImage) ? 2 : -1} aria-label={`The Pokémon On This Card Is ${shuffledCards[roundCounter].name}`}>
            {showAnswerCard && loadCardImage && (<img
            alt=''
            className="answer-card-img mx-auto"
            src={shuffledCards[roundCounter].images.large}
            />
            )}
          </div>
        </div>

        <div className='mid-col show-content flex flex-col justify-between items-center mx-auto relative'
          style={{
            transform: hasTranslated
              ? 'translateX(200%)'
              : showTransitionScreen
              ? 'translateX(-200%)'
              : 'translateX(0%)',
            opacity: hasTranslated
            ? 0
            : showTransitionScreen
            ? 0
            : 1,
          }}>
          <div className='title flex flex-col pointer-events-none select-none' tabIndex={(showCorrectAnswer || showIncorrectAnswer) ? -1 : 6} aria-label='Who Is That Pokémon'>
            <img className='relative' src='/images/top-whos-that.svg' alt=''></img>
            <img className='relative' src='/images/middle-pokemon.svg' alt=''></img>
          </div>
          <div className='frame-container pointer-events-none select-none' tabIndex={(showCorrectAnswer || showIncorrectAnswer) ? -1 : 7}>
            <div className='frame overflow-hidden relative'>
            {!loadCardImage && <h3 className='absolute loading-text'>Loading</h3>}
            {loadCardImage && (
              <img className="card-image absolute"
                style={{ top: `${verticalDistance}px`, left: `${horizontalDistance}px`, transform: `scale(${cardScale})` }}
                // style={{ transform: `scale(${cardScale})` }}
                src={shuffledCards[roundCounter].images.large}
                alt={`The Flavor Text On This Pokémon Card Is: ${
                  shuffledCards[roundCounter]?.flavorText
                  ? shuffledCards[roundCounter]?.flavorText
                  : 'unavailable'
                }`}
              />
              )}
            </div>
          </div>
          <div className='submit-container relative'>
            <input autoFocus tabIndex={(showCorrectAnswer || showIncorrectAnswer) ? -1 : 8} aria-label='Input Your Answer Here.' spellCheck='false' value={guess} onChange={handleInputChange} onKeyDown={handleKeyDown}></input>
            <button tabIndex={(showCorrectAnswer || showIncorrectAnswer) ? -1 : 9} onClick={handleEnter} className='absolute' aria-label='Enter. Click To Submit Your Answer.'>
              <h4 aria-hidden="true"><span>E</span><span>n</span><span>t</span><span>e</span><span>r</span></h4>
            </button>
          </div>
        </div>
      </div>
    </motion.div>
    </section>
  )
}

export default Game;