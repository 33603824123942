import { motion } from 'framer-motion';

function LoadingPage() {
  const loadingVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }, 
    exit: { opacity: 0 }
  }

  return (
    <div id="loading-page" className="relative">
      <motion.div className="absolute"
      key='loading-screen'
      variants={loadingVariants}
      initial='hidden'
      animate='visible'
      exit='exit'
      transition={{ duration: 0.2, ease: [0.42, 0, 0.58, 1] }}>
        <img tabIndex={1} className="rotating-hue mx-auto" src="/images/rotom-phone.png" alt='Loading The Game'></img>
        <h3 className="loading-text">Loading</h3>
      </motion.div>
    </div>
  )
}

export default LoadingPage;
