import { motion } from "framer-motion";
import { useState } from 'react';


function HowToPlayScreen({setShowHowToPlayScreen, setShowCategoriesScreen, setShowStartScreen, shouldStopAnimating, setShouldStopAnimating, isHowBackButtonClicked, setIsHowBackButtonClicked, isCategoriesBackButtonClicked, setIsCategoriesBackButtonClicked}) {
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);

  // changes page animation direction if user is returning from the page ahead
  const areTheyComingBack = () => {
    if (isCategoriesBackButtonClicked) {
      setIsCategoriesBackButtonClicked(false);
      return categoriesBackButtonVariants;
    } if (isBackButtonClicked) {
      return noAnimationVariants;
    } else return contentVariants;
  };
  
  // variants for page animations
  const contentVariants = {
    hidden: { translateX: '100%' },
    visible: { translateX: 0 },
    exit: { translateX: '-150%' }
  };
  
  const buttonVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };
  
  const backButtonVariants = {
    hidden: { translateX: 0 },
    visible: { translateX: 0 },
    exit: { translateX: '150%' }
  }
  
  const categoriesBackButtonVariants = {
    hidden: {
      translateX: '-150%',
    },
    visible: {
      translateX: 0,
      transition: { duration: 0.2 }
    }
  }
  
  const noAnimationVariants = {
    hidden: { opacity: 1 },
    visible: { opacity: 1 }
  };

  // changes states if next button is clicked
  const nextButtonClicked = () => {
    setShouldStopAnimating(false);
    setShowHowToPlayScreen(false);
    setShowCategoriesScreen(true);
  };

  // changes states if next button is clicked
  const backButtonClicked = () => {
    setIsHowBackButtonClicked(true);
    setIsBackButtonClicked(true);
    setShouldStopAnimating(true);
    setShowHowToPlayScreen(false);
    setShowStartScreen(true);
  };

  // allow buttons to be pressed with keyboard for accessibility 
  // const handleNextButtonKeyDown = (event) => {
  //   if (event.key === 'Enter') {
  //     nextButtonClicked();
  //   }
  // }

  // const handleBackButtonKeyDown = (event) => {
  //   if (event.key === 'Enter') {
  //     backButtonClicked();
  //   }
  // };
   
  return(
    <div className='w-full h-full flex flex-col justify-center overflow-x-hidden'>
      <motion.div 
      className=''
      key='how-to-play-screen'
      initial='hidden'
      animate='visible'
      exit='exit'
      variants={isBackButtonClicked ? backButtonVariants : {}}
      transition={{ duration: 0.2, ease: [0.42, 0, 0.58, 1] }}
      >
        <motion.div 
        id="how-to-play-screen" 
        className="container relative px-20"
        key='how-to-play-screen-inner'
        variants={areTheyComingBack()}
        initial='hidden'
        animate='visible'
        exit='exit'
        transition={{ duration: 0.2, ease: [0.42, 0, 0.58, 1] }}
        >
          <div id='how-to-play-title' className='pointer-events-none select-none'>
            <h1><img src='/images/how-to-play.svg' alt='How To Play' tabIndex={1}></img></h1>
          </div>
          <div className="instructions-container flex justify-between mb-10">
            <div className="card dotted flex flex-col">
              <div className='card-frame relative'>
                <img className='absolute' src='/images/jungle-pikachu.webp' alt='A Pokémon Card That Is Really Zoomed In'></img>
              </div>
              <p tabIndex={2}>Guess the Pokemon correctly<br></br>and receive a point!</p>
            </div>
            <div className="card dotted flex flex-col">
              <div className='card-frame relative flex items-center justify-between px-20'>
                <img className='absolute top-0 left-0 w-full h-full object-cover' src='/images/jungle-bg.webp' alt=''></img>
                <img id='lightning' className='ml-4' src='/images/lightning-energy.webp' alt=''></img>
                <img id='basic' className='mr-4' src='/images/basic.png' alt=''></img>
              </div>
              <p tabIndex={3}>You have three hints.<br></br> Use them wisely.</p>
            </div>
            <div className="card dotted flex flex-col">
              <div className='card-frame relative'>
                <img className='absolute' src='/images/jungle-pikachu.webp' alt=''></img>
              </div>
              <p tabIndex={4}>Each round will get harder.<br></br>Can you catch ‘em all?</p>
            </div>
          </div>
        </motion.div>

        <motion.div 
        className="buttons flex justify-between relative container px-20" 
        key='button'
        initial='hidden'
        animate='visible'
        variants={shouldStopAnimating ? buttonVariants : noAnimationVariants}
        transition={{ duration: 0.5, ease: [0.42, 0, 0.58, 1] }}
        >
          <button onClick={backButtonClicked} className="back-button flex justify-center" tabIndex={5} aria-label='Back. Click Here To Go Back To The Start Screen.'>
            <span aria-hidden='true'>B</span><span aria-hidden='true'>a</span><span aria-hidden='true'>c</span><span aria-hidden='true'>k</span>
          </button>
          <button onClick={nextButtonClicked} className="next-button relative flex justify-center" tabIndex={6} aria-label='Next. Click Here To Go To The Next Screen.'>
            <h4 aria-hidden='true'><span>N</span><span>e</span><span>x</span><span>t</span></h4>
          </button>
        </motion.div>
      </motion.div>
    </div>
    
  );
}

export default HowToPlayScreen;