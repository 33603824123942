import { useEffect } from "react";
import { motion } from 'framer-motion';

function GameOver({setIsHoveredOrSelected, valueForGameLength, setShowGameOver, setLoadCardImage, setCategoriesSelected, setGameLengthSelection, score, setScore, setShowStartScreen, setMostRecentToggle, trainerClass, setTrainerClass}) {
  const gameoverVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 }
  }
  
  useEffect(() => {

    const calculateScore = () => {

      const percentage = score / valueForGameLength;

      if (percentage === 1) {
        setTrainerClass('champion');
      } else if (percentage > 0.94) {
        setTrainerClass('eliteFour');
      } else if (percentage > 0.89) {
        setTrainerClass('gymLeader');
      } else if (percentage > 0.79) {
        setTrainerClass('aceTrainer');
      } else if (percentage > 0.69) {
        setTrainerClass('pokemonRanger');
      } else if (percentage > 0.59) {
        setTrainerClass('pokeManiac');
      } else if (percentage > 0.49) {
        setTrainerClass('pokeFan');
      } else if (percentage > 0.39) {
        setTrainerClass('pokemonBreeder');
      } else if (percentage > 0.29) {
        setTrainerClass('rollerskater');
      } else if (percentage > 0.19) {
        setTrainerClass('worker');
      } else {
        setTrainerClass('youngster');
      }
    };

    calculateScore();

  }, [score, valueForGameLength, setTrainerClass]);

  const handleClick = () => {
    setIsHoveredOrSelected(false);
    setLoadCardImage(false);
    setShowGameOver(false);
    setShowStartScreen(true);
    setCategoriesSelected({ wotc: false, ex: false, dpp: false, hgss: false, bw: false, xy: false, sm: false, swsh: false, sv: false });
    setGameLengthSelection({tenCards: false, twentyCards: false, fiftyCards: false, oneHundredCards: false,});
    setScore(0);
    setTrainerClass('');
    setMostRecentToggle([]);
  }

  const trainerLevel = {
    champion: {
      slogan: "Perfection. A true Pokémon master.",
      description: "Champion",
      img: "champion",
      alt: 'Champion'
    },

    eliteFour: {
      slogan: "A near perfect score. Welcome to the Elite Four.",
      description: "Member of the Elite Four",
      img: "elite-four",
      alt: 'Elite Four'
    },

    gymLeader: {
      slogan: "You're strong enough to run a Gym.",
      description: "Gym Leader",
      img: "gym-leader",
      alt: 'Gym Leader'
    },

    aceTrainer: {
      slogan: "You have your eyes set on Victory Road.",
      description: "Ace Trainer",
      img: "ace-trainer", 
      alt: 'Ace Trainer'
    },

    pokemonRanger: {
      slogan: "A worthy foe with room for improvement.",
      description: "Pokémon Ranger",
      img: "pokemon-ranger", 
      alt: 'Pokémon Ranger'
    },

    pokeManiac: {
      slogan: "You know quite a bit about Pocket Monsters.",
      description: "Poké Maniac",
      img: "poke-maniac", 
      alt: 'Poké Maniac'
    },

    pokeFan: {
      slogan: "Your love for Pokemon is admirable.",
      description: "Poké Fan",
      img: "poke-fan", 
      alt: 'Poké Fan'
    },

    pokemonBreeder: {
      slogan: "You care about Pokémon and living harmonious with them.",
      description: "Pokémon Breeder",
      img: "pokemon-breeder", 
      alt: 'Pokémon Breeder'
    },

    rollerskater: {
      slogan: "You're all about having fun!",
      description: "Roller Skater",
      img: "rollerskater", 
      alt: 'Roller Skater'
    },

    worker: {
      slogan: "Don't quit your dayjob.",
      description: "Worker",
      img: "worker", 
      alt: 'Worker'
    },

    youngster: {
      slogan: "You have a lot to learn but keep your head up!",
      description: "Youngster",
      img: "youngster", 
      alt: 'Youngster'
    }

  }

  return(
    <div className='px-20 py-10 mx-auto'>  
    <motion.div id='gameover' className='text-center mx-auto'
      key='gameover-screen'
      variants={gameoverVariants}
      initial='hidden'
      animate='visible'
      exit='exit'
      transition={{ duration: 0.1, ease: [0.42, 0, 0.58, 1] }}>
        <h4 tabIndex={1} id='score-text' className='mb-10'>You guessed {score} out of {valueForGameLength} cards correctly. <br></br>Your rank is...</h4>
        <div className='trainer-image relative mx-auto mb-5'>
          <img src={`/images/trainer/${trainerClass ? trainerLevel[trainerClass].img : ''}.png`} alt=''></img>
        </div>
        <img tabIndex={2} id='trainerclass' alt={(trainerLevel[trainerClass]?.alt && trainerClass) ? trainerLevel[trainerClass]?.alt : 'Trainer'} className='mx-auto' src={`/images/trainer-classes/${trainerClass ? trainerLevel[trainerClass].img : ''}.svg`}></img>
        <h4 tabIndex={3} id='slogan' className='mb-8'>{trainerClass && trainerLevel[trainerClass].slogan}</h4>
        <button tabIndex={4} aria-label='Click To Return To The Home Screen To Play Again' onClick={handleClick}>Play again</button>
    </motion.div>
    <div id='report-a-bug' className='fixed top-0 right-0 p-2'>
      <a className='flex items-center' target='_blank' rel='noopener noreferrer' href='https://forms.gle/U6TE9WHiw6Ui9rGS9'><h4 className='mr-1'>Report a bug</h4><img src='/images/caterpie.png' alt=''></img></a>
    </div>
    </div>
  )
}

export default GameOver;