import {  motion } from "framer-motion";

function StartScreen({setShowStartScreen, showStartScreen, setShowHowToPlayScreen, isHowBackButtonClicked, setIsHowBackButtonClicked}) {
  
  // changes page animation direction if user is returning from the page ahead
  const areTheyComingBack = () => {
    if (isHowBackButtonClicked) {
      setIsHowBackButtonClicked(false);
      return backButtonVariants;
    } else {
      return variants;
    }
  }

  // variants for page animations 
  const backButtonVariants = {
    hidden: {
      translateX: '-150%',
      opacity: 0
    },
    show: {
      translateX: 0,
      opacity: 1,
      transition: { duration: 0.2 }
    }
  }
  
  const variants = {
    hidden: {},
    show: {
      transition: {
        staggerChildren: 0.5,
        delayChildren: 0
      },
    },
    exit: { translateX: '-150%', transition: { duration: 0.4 }}
  }
  
  const imgVariants = {
    hidden: { 
      scale: 0,
      opacity: 0
    },
    show: { 
      scale: 1, 
      opacity: 1,
      transition: { duration: 0.2 } 
    }
  }
  
  const tcgVariants = {
    hidden: { 
      translateX: '-150%',
      opacity: 0,
      skewX: -60
    },
    show: { 
      translateX: 0,
      opacity: 1,
      skewX: 0,
      type: 'spring',
      transition: { 
        delay: isHowBackButtonClicked ? 0.4 : 1,
      }
    }
  }

  // changes states if next button is clicked 
  const nextButtonClicked = () => {
    setShowStartScreen(false);
    setShowHowToPlayScreen(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      nextButtonClicked();
    }
  };

  return(
    <section id='start-screen-container'>
    <motion.div 
    className='start-screen relative overflow-hidden'
    key="start-screen"
    initial='hidden'
    animate='show'
    exit='exit'
    variants={areTheyComingBack()}
    >
      <div className='press-to-start flex justify-end items-center px-4'> 
        <div onClick={nextButtonClicked} className='banner-button' role='button' aria-label='Press Here To Start The Game' tabIndex={4} onKeyDown={(event) => handleKeyPress(event)}>
        <h4 aria-hidden='true' className='select-none'>
          <span>P</span><span>r</span><span>e</span><span>s</span><span>s</span>&nbsp;
          <span>h</span><span>e</span><span>r</span><span>e</span>&nbsp;
          <span>t</span><span>o</span>&nbsp;
          <span>s</span><span>t</span><span>a</span><span>r</span><span>t</span>
        </h4>
        </div>
      </div>
      <div id='title' className='relative z-1 flex items-center justify-center h-full px-20'>
        <motion.div 
        className='container relative'
        key="img-container"
        initial='hidden'
        animate='show'
        variants={areTheyComingBack()}
        >
          <motion.img tabIndex={1} id='logo-top' className='relative pointer-events-none select-none' src='/images/top-whos-that.svg' key='img-1' variants={imgVariants} alt='Who Is That'></motion.img>
          <motion.img tabIndex={2} id='logo-middle' className='relative pointer-events-none select-none' src='/images/middle-pokemon.svg' alt='Pokémon' key='img-2' animate={{ rotate: [-1, 1.2], transition: { duration: 2, ease: 'easeInOut', repeat: Infinity, repeatType: 'reverse' } }} variants={imgVariants}></motion.img>
          <motion.img tabIndex={3} id='logo-bottom' className='relative pointer-events-none select-none' src='/images/pokemon-bottom.svg' alt='Trading Card Game' key='img-3' variants={tcgVariants}></motion.img>
        </motion.div>
      </div>
    </motion.div>
    </section>
  );
}

export default StartScreen;