import { useState } from 'react';
import { motion } from "framer-motion";

function GameLengthScreen({categoriesSelected, setCategoriesSelected, getSetData, gameLengthSelection, setGameLengthSelection, valueforGameLength, setValueForGameLength, cardLengths, setShowCategoriesScreen, setShowGameLengthScreen, isLengthBackButtonClicked, setIsLengthBackButtonClicked}) {
  const [hasntSelectedParameter, setHasntSelectedParameter] = useState(false);
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const [isStartButtonClicked, setIsStartButtonClicked] = useState(false);

  // variants for page animations 
  const variants = {
    hidden: { translateX: '150%' },
    visible: { translateX: 0 },
    exit: { translateX: '-150%' }
  }

  const backButtonVariants = {
    hidden: { translateX: 0 },
    visible: { translateX: 0 },
    exit: { translateX: '150%' }
  }

  const startGameVariants = {
    hidden: {},
    visible: {}, 
    exit: { translateX: '-150%' }
  }

  // updates game length selection based on what user selects
  const handleCardClick = (cardType) => {
    const updatedSelections = { ...gameLengthSelection };
    // if you just selected an option, change all other options to false and set the selected one to true
    if (!updatedSelections[cardType]) {
      for (const key in updatedSelections) {
        if (key !== cardType) {
          updatedSelections[key] = false;
        }
      }
      updatedSelections[cardType] = true;
    } // if you are unselecting an option
      else {
      updatedSelections[cardType] = false;
    }
    setGameLengthSelection(updatedSelections);
  };

  // changes states if back button is clicked
  const backButtonClicked = () => {
    setIsLengthBackButtonClicked(true);
    setIsBackButtonClicked(true);
    setShowGameLengthScreen(false);
    setShowCategoriesScreen(true);
  };

  // check to see if game can start
  const handleSubmit = () => {
    setIsStartButtonClicked(true);
    // checks to see of they have selected a game length in order to continue
    // object.values() creates an array with the values of the keys of categoriesSelected
    // .somee() checks if at least one element in the array satisfies the condition specified by the provided function
    const hasSelectedAGameLength = Object.values(gameLengthSelection).some(value => value);

    if (hasSelectedAGameLength) {
      getSetData();
      setHasntSelectedParameter(false);
      // set's the numerical value of the chosen game
      const selectedLength = Object.keys(gameLengthSelection).find(key => gameLengthSelection[key]);
      const correspondingValue = cardLengths[selectedLength];
      setValueForGameLength(correspondingValue);
    } else {
      setHasntSelectedParameter(true);
    }
  };

  // for disabled users
  const handleKeyDown = (event, category) => {
    if (event.key === 'Enter') {
      handleCardClick(category);
    }
  }

  return(
    <div className='w-full h-full flex flex-col justify-center overflow-x-hidden'>
      <motion.div 
      id='game-length-screen' 
      className="text-center container px-20"
      key='game-length-screen'
      variants={isBackButtonClicked ? backButtonVariants : variants}
      initial='hidden'
      animate='visible'
      exit='exit'
      transition={{ duration: 0.2, ease: [0.42, 0, 0.58, 1] }}
      >
        <div id='game-length-title' className='pointer-events-none select-none mb-20' tabIndex={1}>
          <img src='/images/gamelength.svg' alt='Pick Your Game Length. Choose How Many Cards You Would Like To Guess.'></img>
        </div>

        {/* buttons to choose game length */}

        <div className="flex flex-row justify-between flex-wrap'">
          <div tabIndex={2} onClick={() => handleCardClick('tenCards')} onKeyDown={(event) => handleKeyDown(event, 'tenCards')}  aria-label={`Option One. Ten Cards. For those starting out. Currently ${gameLengthSelection.tenCards ? 'selected.' : 'unselected.'} Click to Change Selection.`} className={`card dotted text-center ${gameLengthSelection.tenCards && 'selected'}`}>
            <div id='green' class='image-container relative'>
              <img className='overlay-image absolute top-0 left-0 w-full h-full object-cover' src='/images/bg-dotted-rays.webp' alt=''></img>
              <img className='relative character' src='/images/green.webp' alt=''></img>
            </div>
            <div class='description p-4'>
              <h4>10 Cards</h4><p>For those starting out.</p>
            </div>
          </div>
          <div tabIndex={3} onKeyDown={(event) => handleKeyDown(event, 'twentyCards')} aria-label={`Option Two. Twenty Cards. Take It Up A Notch. Currently ${gameLengthSelection.twentyCards ? 'selected.' : 'unselected.'} Click to Change Selection.`} onClick={() => handleCardClick('twentyCards')} className={`card dotted text-center ${gameLengthSelection.twentyCards && 'selected'}`}>
            <div id='blue' class='image-container relative'>
              <img className='overlay-image absolute top-0 left-0 w-full h-full object-cover' src='/images/bg-swirl.webp' alt=''></img>
              <img className='relative character' src='/images/blue.webp' alt=''></img>
            </div>
            <div class='description p-4'>
              <h4>20 Cards</h4><p>Take it up a notch.</p>
            </div>
          </div>
          <div tabIndex={4} onKeyDown={(event) => handleKeyDown(event, 'fiftyCards')} aria-label={`Option Three. Fifty Cards. For advanced trainers. Currently ${gameLengthSelection.fiftyCards ? 'selected.' : 'unselected.'} Click to Change Selection.`} onClick={() => handleCardClick('fiftyCards')} className={`card dotted text-center ${gameLengthSelection.fiftyCards && 'selected'}`}>
            <div id='red' class='image-container relative'>
              <img className='overlay-image absolute top-0 left-0 w-full h-full object-cover' src='/images/bg-spatter.webp' alt=''></img>
              <img className='relative character' src='/images/red.webp' alt=''></img>
            </div>
            <div class='description p-4'>
              <h4>50 Cards</h4><p>For advanced trainers.</p>
            </div>
          </div>
          <div tabIndex={5} onKeyDown={(event) => handleKeyDown(event, 'oneHundredCards')} aria-label={`Option Four. One Hundred Cards. Bosses only. Currently ${gameLengthSelection.oneHundredCards ? 'selected.' : 'unselected.'} Click to Change Selection.`} onClick={() => handleCardClick('oneHundredCards')} className={`card dotted text-center ${gameLengthSelection.oneHundredCards && 'selected'}`}>
            <div id='boss' class='image-container relative'>
              <img className='overlay-image absolute top-0 left-0 w-full h-full object-cover' src='/images/bg-star.webp' alt=''></img>
              <img className='relative character' src='/images/boss.webp' alt=''></img>
            </div>
            <div class='description p-4'>
              <h4>100 Cards</h4><p>Bosses only.</p>
            </div>
          </div>
        </div>

        <div className={`warning-text-container absolute hide ${hasntSelectedParameter && 'show'}` }>
          <h4 tabIndex={hasntSelectedParameter ? 6 : -1} className='warning-text'>Please choose a game length</h4>
        </div>
      </motion.div>
      <motion.div className="start-game buttons relative flex justify-between container px-20"
        key='game-length-buttons'
        variants={isStartButtonClicked ? startGameVariants : {}}
        initial='hidden'
        animate='visible'
        exit='exit'
        transition={{ duration: 0.2, ease: [0.42, 0, 0.58, 1] }}>
        <button onClick={backButtonClicked} tabIndex={7} aria-label='Back. Click Here To Go Back To The Categories Screen.' className="back-button relative flex justify-center"><h4 aria-hidden='true'><span>B</span><span>a</span><span>c</span><span>k</span></h4></button>
        <button onClick={handleSubmit} tabIndex={8} aria-label='Start. Click Here To Start The Game. You Must Have Chosen One Game Length To Continue.' className="p-1"><span aria-hidden='true'>s</span><span aria-hidden='true'>t</span><span aria-hidden='true'>a</span><span aria-hidden='true'>r</span><span aria-hidden='true'>t</span></button>
      </motion.div>
    </div>
  )
}

export default GameLengthScreen;