import { motion } from "framer-motion";
import { useState, useEffect } from 'react';

function CategoriesScreen({setShowCategoriesScreen, showCategoriesScreen, setShowGameLengthScreen, setShowHowToPlayScreen, categoriesSelected, setCategoriesSelected, isCategoriesBackButtonClicked, setIsCategoriesBackButtonClicked, isLengthBackButtonClicked, setIsLengthBackButtonClicked, mostRecentToggle, setMostRecentToggle, isHoveredOrSelected, setIsHoveredOrSelected}) {
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const [categoriesHovered, setCategoriesHovered] = useState(
    {
      wotc: false,
      ex: false,
      dpp: false,
      hgss: false,
      bw: false,
      xy: false,
      sm: false,
      swsh: false,
      sv: false,
    }
  );
  const [hasntSelectedParameter, setHasntSelectedParameter] = useState(false);

  const imgList = {
      wotc: '/images/wotc-category.png',
      ex: '/images/ex-era-category.png',
      dpp: '/images/dpp-category.png',
      hgss: '/images/hgss-category.png',
      bw: '/images/bw-category.png',
      xy: '/images/xy-category.png',
      sm: '/images/sm-category.png',
      swsh: '/images/swsh-category.png',
      sv: '/images/sv-category.png'
  };

  useEffect(() => {
    if (Object.values(categoriesHovered).some(value => value === true) || Object.values(categoriesSelected).some(value => value === true)) {
      setIsHoveredOrSelected(true);
    } else {
      setIsHoveredOrSelected(false);
    }
  }, [categoriesHovered, categoriesSelected, setIsHoveredOrSelected])

  // changes page animation direction if user is returning from the page ahead
  const areTheyComingBack = () => {
    if (isBackButtonClicked) {
      return backButtonVariants;
    } else if (isLengthBackButtonClicked) {
      setIsLengthBackButtonClicked(false);
      return gameLengthBackButtonVariants;
    } else return contentVariants;
  };

  // variants for page animations 
  const contentVariants = {
    hidden: { translateX: '100%' },
    visible: { translateX: 0 },
    exit: { translateX: '-150%', }
  };

  const backButtonVariants = {
    hidden: { translateX: 0 },
    visible: { translateX: 0 },
    exit: { translateX: '150%' }
  }

  const gameLengthBackButtonVariants = {
    hidden: {
      translateX: '-150%',
    },
    visible: {
      translateX: 0,
      transition: { duration: 0.2 }
    }
  }

  // handles states if the next button is clicked 
  const nextButtonClicked = () => {
    const hasSelectedACategory = Object.values(categoriesSelected).some(value => value);
    if (hasSelectedACategory) {
      setShowCategoriesScreen(false);
      setShowGameLengthScreen(true);
      setHasntSelectedParameter(false);
    } else {
      setHasntSelectedParameter(true);
    }
  };

  // handles states if the backbutton is clicked
  const backButtonClicked = () => {
    setIsCategoriesBackButtonClicked(true);
    setIsBackButtonClicked(true);
    setShowCategoriesScreen(false);
    setShowHowToPlayScreen(true);
  };

  // updates category hovered to true if a category is hovered over 
  const handleEnterHover = (category) => {
    setCategoriesHovered((prev) => ({ ...prev, [category]: true }));
  };

  // updates category hovered to false if that category is not being hovered over anymore
  const handleExitHover = (category) => {
    setCategoriesHovered((prev) => ({ ...prev, [category]: false }));
  };

  // stores whether user has selected over atleast 1 category
  const selectionHasTrueValue = Object.values(categoriesSelected).some(value => value === true);

  // stores whether user has hovered over atleast 1 category
  const hoverHasTrueValue = Object.values(categoriesHovered).some(value => value === true);

  const renderImg = () => {
    // if they have not selected a category and they're not hovering over any categories 
    if (!selectionHasTrueValue && !hoverHasTrueValue) {
      return '';
    } // if they have not selected a category and they're hovering over a category -> set that true value's key to be the img
      else if (!selectionHasTrueValue && hoverHasTrueValue) {
        const keyWithTrueValue = Object.keys(categoriesHovered).find(key => categoriesHovered[key]);
        const matchingValue = imgList[keyWithTrueValue]; 
        return matchingValue;
    } // if they have selected a category and are hovering over a category
      else if (selectionHasTrueValue && hoverHasTrueValue) {
        return handleHoverAndSelection();
    } // if they have selected a category and they're not hovering over any categories -> set the true value's key to be the img
      else if (selectionHasTrueValue && !hoverHasTrueValue) {
        const keyWithTrueValue = mostRecentToggle[mostRecentToggle.length - 1];
        const matchingValue = imgList[keyWithTrueValue];
        return matchingValue;
    } else return '';
  };

  // handles the case when user has selected a category & is hovering over a category
  const handleHoverAndSelection = () => {
    const keyWithTrueValue = Object.keys(categoriesHovered).find(key => categoriesHovered[key]);
    // if key value true in categoriesHovered has a true value in categoriesSelected -> make that key the img 
    if (categoriesSelected[keyWithTrueValue]) {
      const matchingValue = imgList[keyWithTrueValue];
      return matchingValue;
    } // if key value in categoriesHovered does not have a true value in categoriesSelected -> set that key value to be the img 
      else if (!categoriesSelected[keyWithTrueValue]) {
        const matchingValue = imgList[keyWithTrueValue];
        return matchingValue;
      } else return '';
  };

  // updates which category was most recently selected & which categories have been selected in total
  const toggleSelection = (category) => {
    if (mostRecentToggle[mostRecentToggle.length - 1] === category) {
      setMostRecentToggle((prev) => prev.slice(0, -1));
    } if (mostRecentToggle.includes(category)) {
      const arrayWithRemovedCategory = mostRecentToggle.filter(item => item !== category);
      setMostRecentToggle(arrayWithRemovedCategory);
    } else {
      setMostRecentToggle((prev) => [...prev, category]);
    }
    const copyOfCategoriesSelected = {...categoriesSelected};
    copyOfCategoriesSelected[category] = !copyOfCategoriesSelected[category];
    setCategoriesSelected(copyOfCategoriesSelected);
  };

  const handleKeyDown = (event, category) => {
    if (event.key === 'Enter') {
      toggleSelection(category);
    }
  }

  return(
    <div className='w-full h-full flex flex-col justify-center overflow-x-hidden'>
      <motion.div 
      id='categories-screen' 
      className='container px-20 relative'
      key='categories-screen'
      variants={areTheyComingBack()}
      initial='hidden'
      animate='visible'
      exit='exit'
      transition={{ duration: 0.2, ease: [0.42, 0, 0.58, 1] }}
      >
        <div id='categories-title' className='pointer-events-none select-none mb-20'>
          <h1><img src='/images/categories.svg' alt='Choose Your Categories. Choose As Many As You Like.' tabIndex={1}></img></h1>
        </div>
        <div className='categories-container flex flex-row justify-between'>
          <div id='tiles' className='categories flex flex-wrap justify-between'>
            <div tabIndex={2} role='button' aria-label={`Category One. Wizards Of The Coast. Currently ${categoriesSelected.wotc ? 'selected.' : 'unselected.'} Click to Change Selection.`} onClick={() => toggleSelection('wotc')} onKeyDown={(event) => handleKeyDown(event, 'wotc')} onMouseEnter={() => handleEnterHover('wotc')} onMouseLeave={() => handleExitHover('wotc')} className={`tile wotc dotted select-none flex items-center justify-center ${categoriesSelected.wotc && 'selected'}`}>
              <h3>Wizards Of the Coast</h3>
            </div>
            <div tabIndex={3} role='button' aria-label={`Category Two. E X. Currently ${categoriesSelected.ex ? 'selected.' : 'unselected.'} Click to Change Selection.`} onClick={() => toggleSelection('ex')} onKeyDown={(event) => handleKeyDown(event, 'ex')} onMouseEnter={() => handleEnterHover('ex')} onMouseLeave={() => handleExitHover('ex')} className={`tile ex dotted select-none flex items-center justify-center ${categoriesSelected.ex && 'selected'}`}>
              <h3 aria-label='E X'>EX</h3>
            </div>
            <div tabIndex={4} role='button' aria-label={`Category Three. Diamond And Pearl. Currently ${categoriesSelected.dpp ? 'selected.' : 'unselected.'} Click to Change Selection.`} onClick={() => toggleSelection('dpp')} onKeyDown={(event) => handleKeyDown(event, 'dpp')} onMouseEnter={() => handleEnterHover('dpp')} onMouseLeave={() => handleExitHover('dpp')} className={`tile dpp dotted select-none flex items-center justify-center ${categoriesSelected.dpp && 'selected'}`}>
              <h3>Diamond & Pearl</h3>
            </div>
            <div tabIndex={5} role='button' aria-label={`Category Four. Heart Gold And Silver. Currently ${categoriesSelected.hgss ? 'selected.' : 'unselected.'} Click to Change Selection.`} onClick={() => toggleSelection('hgss')} onKeyDown={(event) => handleKeyDown(event, 'hgss')} onMouseEnter={() => handleEnterHover('hgss')} onMouseLeave={() => handleExitHover('hgss')} className={`tile hgss dotted select-none flex items-center justify-center ${categoriesSelected.hgss && 'selected'}`}>
              <h3>HeartGold SoulSilver</h3>
            </div>
            <div tabIndex={6} role='button' aria-label={`Category Five. Black And White. Currently ${categoriesSelected.bw ? 'selected.' : 'unselected.'} Click to Change Selection.`} onClick={() => toggleSelection('bw')} onKeyDown={(event) => handleKeyDown(event, 'bw')} onMouseEnter={() => handleEnterHover('bw')} onMouseLeave={() => handleExitHover('bw')} className={`tile bw dotted select-none flex items-center justify-center ${categoriesSelected.bw && 'selected'}`}>
              <h3>Black & White</h3>
            </div>
            <div tabIndex={7} role='button' aria-label={`Category Six. X Y. Currently ${categoriesSelected.xy ? 'selected.' : 'unselected.'} Click to Change Selection.`} onClick={() => toggleSelection('xy')} onKeyDown={(event) => handleKeyDown(event, 'xy')} onMouseEnter={() => handleEnterHover('xy')} onMouseLeave={() => handleExitHover('xy')} className={`tile xy dotted select-none flex items-center justify-center ${categoriesSelected.xy && 'selected'}`}>
              <h3 aria-label='X Y'>XY</h3>
            </div>
            <div tabIndex={8} role='button' aria-label={`Category Seven. Sun And Moon. Currently ${categoriesSelected.sm ? 'selected.' : 'unselected.'} Click to Change Selection.`} onClick={() => toggleSelection('sm')} onKeyDown={(event) => handleKeyDown(event, 'sm')} onMouseEnter={() => handleEnterHover('sm')} onMouseLeave={() => handleExitHover('sm')} className={`tile sm dotted select-none flex items-center justify-center ${categoriesSelected.sm && 'selected'}`}>
              <h3>Sun & Moon</h3>
            </div>
            <div tabIndex={9} role='button' aria-label={`Category Eight. Sword And Shield. Currently ${categoriesSelected.swsh ? 'selected.' : 'unselected.'} Click to Change Selection.`} onClick={() => toggleSelection('swsh')} onKeyDown={(event) => handleKeyDown(event, 'swsh')} onMouseEnter={() => handleEnterHover('swsh')} onMouseLeave={() => handleExitHover('swsh')} className={`tile swsh dotted select-none flex items-center justify-center ${categoriesSelected.swsh && 'selected'}`}>
              <h3>Sword & Shield</h3>
            </div>
            <div tabIndex={10} role='button' aria-label={`Category Nine. Scarlet And Violet. Currently ${categoriesSelected.sv ? 'selected.' : 'unselected.'} Click to Change Selection.`} onClick={() => toggleSelection('sv')} onKeyDown={(event) => handleKeyDown(event, 'sv')} onMouseEnter={() => handleEnterHover('sv')} onMouseLeave={() => handleExitHover('sv')} className={`tile sv dotted select-none flex items-center justify-center ${categoriesSelected.sv && 'selected'}`}>
              <h3>Scarlet & Violet</h3>
            </div>
          </div>
          <div id='category-images' className='img-container relative'>
            {isHoveredOrSelected && <img src={renderImg()} alt=''></img>}
          </div>
        </div>
        <div className={`warning-text-container absolute hide ${hasntSelectedParameter && 'show'}` }>
          <h4 tabIndex={hasntSelectedParameter ? 11 : -1} className='warning-text'>Please choose at least one category</h4>
        </div>
      </motion.div>
      <div className='buttons flex justify-between relative container px-20'>
        <button tabIndex={12} onClick={backButtonClicked} aria-label='Back. Click Here To Go Back To The How To Play Screen.' className="back-button relative flex justify-center"><h4 aria-hidden='true'><span>B</span><span>a</span><span>c</span><span>k</span></h4></button>
        <button tabIndex={13} onClick={nextButtonClicked} aria-label='Next. Click Here To Go To The Next Screen. You Must Have Chosen At Least One Category To Continue.' className="next-button relative flex justify-center"><h4 aria-hidden='true'><span>N</span><span>e</span><span>x</span><span>t</span></h4></button>
      </div>
    </div>
  );
}

export default CategoriesScreen;