import './App.scss';
import pokemon from 'pokemontcgsdk';
import GameLengthScreen from './components/GameLengthScreen';
import Game from './components/Game';
import LoadingPage from './components/LoadingPage';
import { useState, useEffect } from 'react';
import StartScreen from './components/StartScreen';
import HowToPlayScreen from './components/HowToPlayScreen';
import CategoriesScreen from './components/CategoriesScreen';
import GameOver from './components/GameOver';
import PreloadImages from './components/PreloadImages'; // Ensure the path matches your file structure
import { AnimatePresence } from 'framer-motion';

function App() {

  pokemon.configure({apiKey: '02670c85-fbc6-41be-acb6-2d64b6984b24'})

  // Array with all the Pokemon sets - No promos, etc
  const sets = {
    wotc: ['base1', 'base2', 'base3', 'base4', 'base5', 'gym1', 'gym2', 'neo1', 'neo2', 'neo3', 'neo4', 'ecard1', 'ecard2', 'ecard3'],
    ex: ['ex1', 'ex2', 'ex3', 'ex4', 'ex5', 'ex6', 'ex7', 'ex8', 'ex9', 'ex10', 'ex11', 'ex12', 'ex13', 'ex14', 'ex15', 'ex16'],
    dpp: ['dp1', 'dp2', 'dp3', 'dp4', 'dp5', 'dp6', 'dp7', 'pl1', 'pl2', 'pl3', 'pl4'],
    hgss: ['hgss1', 'hgss2', 'hgss3', 'hgss4', 'col1'],
    bw: ['bw1', 'bw2', 'bw3', 'bw4', 'bw5', 'bw6', 'dv1', 'bw7', 'bw8', 'bw9', 'bw10', 'bw11'],
    xy: ['xy1', 'xy2', 'xy3', 'xy4', 'xy5', 'dc1', 'xy6', 'xy7', 'xy8', 'xy9', 'g1', 'xy10', 'xy11', 'xy12'],
    sm: ['sm1', 'sm2', 'sm3', 'sm35', 'sm4', 'sm5', 'sm6', 'sm7', 'sm75', 'sm8', 'sm9', 'sm10', 'sm11', 'sm115', 'sma', 'sm12'],
    swsh: ['swsh1', 'swsh2', 'swsh3', 'swsh35', 'swsh4', 'swsh45', 'swsh45sv', 'swsh5', 'swsh6', 'swsh7', 'cel25', 'cel25c', 'swsh8', 'swsh9', 'swsh9tg', 'swsh10', 'pgo', 'swsh11', 'swsh11tg', 'swsh12', 'swsh12tg', 'swsh12pt5', 'swsh12pt5gg'],
    sv: ['sv1', 'sv2', 'sv3', 'sv3pt5', 'sv4']
  };

  const [categoriesSelected, setCategoriesSelected] = useState(
    {
      wotc: false,
      ex: false,
      dpp: false,
      hgss: false,
      bw: false,
      xy: false,
      sm: false,
      swsh: false,
      sv: false,
    }
  );

  const cardLengths = {
    tenCards: 10,
    twentyCards: 20,
    fiftyCards: 50,
    oneHundredCards: 100,
  };

  const [gameLengthSelection, setGameLengthSelection] = useState({
    tenCards: false,
    twentyCards: false,
    fiftyCards: false,
    oneHundredCards: false,
  });

  const imagePaths = [
    '/images/rotom-phone.png',
    '/images/basic.png',
    '/images/bg-dotted-rays.webp',
    '/images/bg-spatter.webp',
    '/images/bg-star.webp',
    '/images/bg-swirl.webp',
    '/images/blue.webp',
    '/images/boss.webp',
    '/images/bw-category.png',
    '/images/categories.svg',
    '/images/caterpie.png',
    '/images/correct.svg',
    '/images/dots.png',
    '/images/dpp-category.png',
    '/images/ex-era-category.png',
    '/images/gamelength.svg',
    '/images/green.webp',
    '/images/hgss-category.png',
    '/images/how-to-play.svg',
    '/images/jungle-bg.webp',
    '/images/jungle-pikachu.webp',
    '/images/lightning-energy.webp',
    '/images/middle-pokemon.svg',
    '/images/pokeball-texture.png',
    '/images/pokemon-bottom.svg',
    '/images/red.webp',
    '/images/sm-category.png',
    '/images/sv-category.png',
    '/images/swsh-category.png',
    '/images/top-whos-that.svg',
    '/images/wotc-category.png',
    '/images/wrong.svg',
    '/images/xy-category.png',
    '/images/trainer/ace-trainer.png',
    '/images/trainer/champion.png',
    '/images/trainer/elite-four.png',
    '/images/trainer/gym-leader.png',
    '/images/trainer/poke-fan.png',
    '/images/trainer/poke-maniac.png',
    '/images/trainer/pokemon-breeder.png',
    '/images/trainer/pokemon-ranger.png',
    '/images/trainer/rollerskater.png',
    '/images/trainer/worker.png',
    '/images/trainer/youngster.png',
    '/images/trainer-classes/ace-trainer.svg',
    '/images/trainer-classes/champion.svg',
    '/images/trainer-classes/elite-four.svg',
    '/images/trainer-classes/gym-leader.svg',
    '/images/trainer-classes/poke-fan.svg',
    '/images/trainer-classes/poke-maniac.svg',
    '/images/trainer-classes/pokemon-breeder.svg',
    '/images/trainer-classes/pokemon-ranger.svg',
    '/images/trainer-classes/rollerskater.svg',
    '/images/trainer-classes/worker.svg',
    '/images/trainer-classes/youngster.svg',
    '/images/home.png'
  ];

  const [valueForGameLength, setValueForGameLength] = useState(0);
  const [shuffledCards, setShuffledCards] = useState([]);
  const [score, setScore] = useState(0);

  // Booleans for game states
  const [showGameLengthScreen, setShowGameLengthScreen] = useState(false);
  const [showGame, setShowGame] = useState(false);
  const [showStartScreen, setShowStartScreen] = useState(false);
  const [showHowToPlayScreen, setShowHowToPlayScreen] = useState(false);
  const [showCategoriesScreen, setShowCategoriesScreen] = useState(false);
  const [showLoadingPage, setShowLoadingPage] = useState(true);
  const [loadCardImage, setLoadCardImage] = useState(false);
  const [showGameOver, setShowGameOver] = useState(false);
  const [shouldStopAnimating, setShouldStopAnimating] = useState(true);
  const [mostRecentToggle, setMostRecentToggle] = useState([]);
  const [trainerClass, setTrainerClass] = useState('');
  const [isHoveredOrSelected, setIsHoveredOrSelected] = useState(false);

  // back buttons observance for appropriate page transations 
  const [isHowBackButtonClicked, setIsHowBackButtonClicked] = useState(false);
  const [isCategoriesBackButtonClicked, setIsCategoriesBackButtonClicked] = useState(false);
  const [isLengthBackButtonClicked, setIsLengthBackButtonClicked] = useState(false);

  useEffect(() => {
    const detectTouch = () => {
      if ('ontouchstart' in window || navigator.maxTouchPoints) {
        document.body.classList.add('touch-device');
      } else {
        document.body.classList.add('no-touch-device');
      }
    };

    detectTouch();

    function adjustVH() {
      // Calculate the viewport height and multiply it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    
    // Listen for resize events
    window.addEventListener('resize', adjustVH);
    // Call the function to adjust the height initially
    adjustVH();

    setTimeout(() => {
      setShowLoadingPage(false);
      setShowStartScreen(true);
    }, 2000); // 2000 milliseconds = 2 seconds

  }, []);


  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const getSetData = () => {
    let filteredCardsSelected = [];
    let shortenedShuffledCards = [];
    setShowGameLengthScreen(false);
    setShowLoadingPage(true);

    // Find which categories are selected & put them into an array
    const keysWithTrueValue = Object.keys(categoriesSelected).filter(key => categoriesSelected[key]);

    // Get all sets from each category & put them into an array
    const combinedArray = keysWithTrueValue.reduce((result, key) => {
      return result.concat(sets[key]);
    }, []);

    // Generate query string for Pokemon TCG API & fetch cards
    const queryString = combinedArray.map((setId) => `set.id:${setId} supertype:Pokémon`);
    const pokemonCardsPromises = queryString.map((query) => pokemon.card.where({ q: query }));
    Promise.all(pokemonCardsPromises)
      .then(result => {
        const cardsSelected = result.flatMap(obj => obj.data);
        // create a shuffled array with Pokemon that are only one word and remove any accents
        cardsSelected.forEach(card => {
          if (!card.name.indexOf(' ') >= 0 && !card.name.includes(' ') && !/[^a-zA-Z\s]/.test(card.name)) {
            const modifiedName = card.name.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase();
            card.name = modifiedName;
            filteredCardsSelected.push(card);
          } else {}
        });
        const largeShuffledCards = shuffleArray(filteredCardsSelected);

        // shorten the array to the game length selected
        for (const key in gameLengthSelection) {
          if (gameLengthSelection[key]) {
            const keyName = key;
            const length = cardLengths[keyName];
            if (length !== undefined) {
            shortenedShuffledCards = largeShuffledCards.slice(0, length);
            break;
            }     
          }
        }
        setShuffledCards(shortenedShuffledCards);
        setShowLoadingPage(false);
        setShowGame(true);
      })
  };

  return (
    <div className='App relative flex items-center'>
      <PreloadImages imagePaths={imagePaths} />
      <div className="moving-background absolute"></div>
        <div className='game-container relative flex items-center w-full'> 
          <AnimatePresence mode='wait'>
            {showStartScreen && <StartScreen key='start-screen' setShowStartScreen={setShowStartScreen} setShowHowToPlayScreen={setShowHowToPlayScreen} isHowBackButtonClicked={isHowBackButtonClicked} setIsHowBackButtonClicked={setIsHowBackButtonClicked}></StartScreen>}
            {showHowToPlayScreen && <HowToPlayScreen key='how-to-play-screen' setShowHowToPlayScreen={setShowHowToPlayScreen} setShowCategoriesScreen={setShowCategoriesScreen} showStartScreen={showStartScreen} setShowStartScreen={setShowStartScreen} shouldStopAnimating={shouldStopAnimating} setShouldStopAnimating={setShouldStopAnimating} isHowBackButtonClicked={isHowBackButtonClicked} setIsHowBackButtonClicked={setIsHowBackButtonClicked} isCategoriesBackButtonClicked={isCategoriesBackButtonClicked} setIsCategoriesBackButtonClicked={setIsCategoriesBackButtonClicked}></HowToPlayScreen>}
            {showCategoriesScreen && <CategoriesScreen key='categories-screen' setShowCategoriesScreen={setShowCategoriesScreen} setShowGameLengthScreen={setShowGameLengthScreen} setShowHowToPlayScreen={setShowHowToPlayScreen} categoriesSelected={categoriesSelected} setCategoriesSelected={setCategoriesSelected} isCategoriesBackButtonClicked={isCategoriesBackButtonClicked} setIsCategoriesBackButtonClicked={setIsCategoriesBackButtonClicked} isLengthBackButtonClicked={isLengthBackButtonClicked} setIsLengthBackButtonClicked={setIsLengthBackButtonClicked} showCategoriesScreen={showCategoriesScreen} mostRecentToggle={mostRecentToggle} setMostRecentToggle={setMostRecentToggle} isHoveredOrSelected={isHoveredOrSelected} setIsHoveredOrSelected={setIsHoveredOrSelected}></CategoriesScreen>}
            {showGameLengthScreen && <GameLengthScreen key='game-length-screen' categoriesSelected={categoriesSelected} setCategoriesSelected={setCategoriesSelected} getSetData={getSetData} gameLengthSelection={gameLengthSelection} setGameLengthSelection={setGameLengthSelection} valueForGameLength={valueForGameLength} setValueForGameLength={setValueForGameLength} cardLengths={cardLengths} setShowGameLengthScreen={setShowGameLengthScreen} setShowCategoriesScreen={setShowCategoriesScreen} isLengthBackButtonClicked={isLengthBackButtonClicked} setIsLengthBackButtonClicked={setIsLengthBackButtonClicked}></GameLengthScreen>}
            {showLoadingPage && <LoadingPage key='loading-screen'></LoadingPage>}
            {showGame && <Game key='game-screen' shuffledCards={shuffledCards} loadCardImage={loadCardImage} setLoadCardImage={setLoadCardImage} showGame={showGame} setShowGame={setShowGame} showGameOver={showGameOver} setShowGameOver={setShowGameOver} gameLengthSelection={gameLengthSelection} setGameLengthSelection={setGameLengthSelection} valueForGameLength={valueForGameLength} setValueForGameLength={setValueForGameLength} score={score} setScore={setScore} cardLengths={cardLengths} setIsHoveredOrSelected={setIsHoveredOrSelected} setShowStartScreen={setShowStartScreen} setCategoriesSelected={setCategoriesSelected} setTrainerClass={setTrainerClass} setMostRecentToggle={setMostRecentToggle}></Game>}
            {showGameOver && <GameOver key='gameover-screen' setIsHoveredOrSelected={setIsHoveredOrSelected} valueForGameLength={valueForGameLength} showGameLengthScreen={showGameLengthScreen} setShowGameLengthScreen={setShowGameLengthScreen} showGameOver={showGameOver} setShowGameOver={setShowGameOver} setLoadCardImage={setLoadCardImage} categoriesSelected={categoriesSelected} setCategoriesSelected={setCategoriesSelected} gameLengthSelection={gameLengthSelection} setGameLengthSelection={setGameLengthSelection} score={score} setScore={setScore} setShowStartScreen={setShowStartScreen} setMostRecentToggle={setMostRecentToggle} trainerClass={trainerClass} setTrainerClass={setTrainerClass}></GameOver>}
          </AnimatePresence>
        </div>
    </div>
  );
}

export default App;
