// PreloadImages.js
function PreloadImages({ imagePaths }) {
  return (
    <div style={{ display: 'none' }}>
      {imagePaths.map((path, index) => (
        <img key={index} src={path} alt="" />
      ))}
    </div>
  );
}

export default PreloadImages;